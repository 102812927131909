import React, { useEffect, useState } from 'react';
import { List, Select, Empty, Tag, Flex, Divider, Skeleton} from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { isMobile } from 'react-device-detect';
import * as Constant from '../Constants';
import DynamicProductCard from './DynamicProductCard';
import LoadingCard from './LoadingCard';
import { isMedMenu } from '../utility/generalUtils';
import { notNull } from '../sharedFunctions';


const ProductsScroll = ({products, title, filtersToShow, activeFilters, ready}) => {
  const navigate = useNavigate();
  const loading = useSelector((state) => state?.menuFeed?.loading);
  const [cardLimit, setCardLimit] = useState(20);
  const [sortedProducts, setSortedProducts] = useState(products);
  const [displayOrder, setDisplayOrder] = useState('asc');
  const settings = useSelector((state) => state?.content?.contentData?.menu);
  const primaryThc = settings?.primary_thc.includes(' total') ? `total_${settings?.primary_thc.replace(' total', '')}` : settings?.primary_thc;
  const primaryCbd = settings?.primary_cbd.includes(' total') ? `total_${settings?.primary_thc.replace(' total', '')}` : settings?.primary_thc;

  // useEffect(() => {
  //   setSortedProducts(sessionStorage.getItem('menuType') === 'Recreational' ? products : products?.filter(prod => prod?.variants?.some(variant => variant?.med_price_cents === true)));
  // }, [products]);

  // if (Object.keys(activeFilters).length === 1) {
  //   const filterKey = Object.keys(activeFilters)[0];

  //   title = filterKey + ': ' + activeFilters[filterKey];
  // }

  useEffect(() => {
    if( products ) {
      sortProducts(displayOrder);
    }
    // setSortedProducts(sessionStorage.getItem('menuType') === 'Recreational' ? products : products?.filter(prod => prod?.variants?.some(variant => variant?.med_price_cents === true)));
  }, [products, displayOrder, activeFilters]);

  const sortProducts = (order) => {
    const testResultType = (product, type) => {
      if(settings?.range_display_test_results &&
        product?.test_results?.[type]?.low !== product?.test_results?.[type]?.high){
        return product?.test_results?.[type]?.high
      }else {
        return product?.test_results?.[type]?.current
      }
    }
    if (order === "asc") {

      return setSortedProducts(products.slice().sort((a, b) => a.name.localeCompare(b.name)));

    } else if (order === "desc") {

      return setSortedProducts(products.slice().sort((a, b) => b.variants[0].name.localeCompare(a.variants[0].name)));

    } else if (order === "price-asc") {
      if (activeFilters?.['weights']) {
        return setSortedProducts(products.slice().sort((a, b) => {
          const aVariant = selectVariantWithWeightFilter(a, activeFilters?.['weights']);
          const bVariant = selectVariantWithWeightFilter(b, activeFilters?.['weights']);
          if( aVariant && bVariant ) {
            return( selectPriceToCompare(aVariant) - selectPriceToCompare(bVariant));
          }
          if( !notNull(aVariant) && !notNull(bVariant) ) {
            return (0)
          } else if( !notNull(aVariant) ) {
            return (-1)
          }else {
            return (1)
          }
        }));
      } else {
        return setSortedProducts(products.slice().sort((a, b) => a.variants[0].price_cents - b.variants[0].price_cents));
      }
    } else if (order === "price-desc") {
      if (activeFilters?.['weights']) {
        return setSortedProducts(products.slice().sort((a, b) => {
          const aVariant = selectVariantWithWeightFilter(a, activeFilters?.['weights']);
          const bVariant = selectVariantWithWeightFilter(b, activeFilters?.['weights']);
          if( aVariant && bVariant ) {
            return( selectPriceToCompare(bVariant) - selectPriceToCompare(aVariant));
          }
          if( !notNull(aVariant) && !notNull(bVariant) ) {
            return (0)
          } else if( !notNull(aVariant) ) {
            return (-1)
          }else {
            return (1)
          }
        }));
      } else {
        return setSortedProducts(products.slice().sort((a, b) => b.variants[0].price_cents - a.variants[0].price_cents));
      }

    } else if (order === "thc-low-high") {

      return setSortedProducts(products.slice().sort((a, b) => {
        const aUnitType = a.test_results?.[primaryThc]?.unit_type;
        const bUnitType = b.test_results?.[primaryThc]?.unit_type;
        const aTestResultType = testResultType(a, primaryThc);
        const bTestResultType = testResultType(b, primaryThc);

        if (aUnitType === '%' && bUnitType === 'mg') {
          return -1;
        } else if (aUnitType === 'mg' && bUnitType === '%') {
          return 1;
        } else {
          return aTestResultType - bTestResultType;
        }
      }));
    } else if (order === "thc-high-low") {

      return setSortedProducts(products.slice().sort((a, b) => {
        const aUnitType = a.test_results?.[primaryThc]?.unit_type;
        const bUnitType = b.test_results?.[primaryThc]?.unit_type;
        const aTestResultType = testResultType(a, primaryThc);
        const bTestResultType = testResultType(b, primaryThc);

        if (bUnitType === '%' && aUnitType === 'mg') {
          return -1;
        } else if (bUnitType === 'mg' && aUnitType === '%') {
          return 1;
        } else {
          return bTestResultType - aTestResultType;
        }
      }));
    } else if (order === "cbd-low-high") {

      return setSortedProducts(products.slice().sort((a, b) => {
        const aUnitType = a.test_results?.[primaryCbd]?.unit_type;
        const bUnitType = b.test_results?.[primaryCbd]?.unit_type;
        const aTestResultType = testResultType(a, primaryCbd);
        const bTestResultType = testResultType(b, primaryCbd);

        if (aUnitType === '%' && bUnitType === 'mg') {
          return -1;
        } else if (aUnitType === 'mg' && bUnitType === '%') {
          return 1;
        } else {
          return aTestResultType - bTestResultType;
        }
      }));
    } else if (order === "cbd-high-low") {

      return setSortedProducts(products.slice().sort((a, b) => {
        const aUnitType = a.test_results?.[primaryCbd]?.unit_type;
        const bUnitType = b.test_results?.[primaryCbd]?.unit_type;
        const aTestResultType = testResultType(a, primaryCbd);
        const bTestResultType = testResultType(b, primaryCbd);

        if (bUnitType === '%' && aUnitType === 'mg') {
          return -1;
        } else if (bUnitType === 'mg' && aUnitType === '%') {
          return 1;
        } else {
          return bTestResultType - aTestResultType;
        }
      }));

    } else if (order === "popularity-high-low") {

      return setSortedProducts(products.slice().sort((a, b) => a.popularity - b.popularity));

    } else if (order === "popularity-low-high") {

      return setSortedProducts(products.slice().sort((a, b) => b.popularity - a.popularity));
    }
  };

  const handleSort = (sortBy) => {
    setDisplayOrder(sortBy);
    sortProducts(sortBy);
  }

  const selectVariantWithWeightFilter = (product, weights) => {
    return product.variants.find((v) => weights.includes(v.display_weight));
  }

  const selectPriceToCompare = (v) => {
    if(!notNull(v)) {return null };
    if( isMedMenu()) {
      return v.discounted_med_price_cents || v.med_price_cents;
    } else {
      return v.discounted_price_cents || v.price_cents;
    }
  }

  const removeFilter = (filter, value) => {
    const newFilters = {...activeFilters};
    newFilters[filter] = newFilters[filter].filter((item) => item !== value);
    if (newFilters[filter].length === 0) {
      delete newFilters[filter];
    }
    const queryParams = new URLSearchParams(newFilters).toString();
    navigate(`/products?${queryParams}`, { state: { filters: newFilters } });
  }

  const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input?.toLowerCase());

  return (
    <>
    {
      !products?.length
      ?
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No Products'/>
      :
      <section id="product_section">

        <div className="">
          <Flex className="back-btn" justify="space-between" align="flex-start" >
            <Link onClick={() => navigate(-1)}>
              <i className="fa fa-arrow-left"/> Back
            </Link>
            <Select
              suffixIcon={<i className="fa fa-chevron-down" style={{color: "grey"}}/>}
              showSearch
              placeholder="Sort By"
              optionFilterProp="children"
              onChange={handleSort}
              filterOption={filterOption}
              style={{ width: isMobile ? "150px" : "200px", float:"right" }}
              dropdownStyle={{ width: isMobile ? "200px" : "200px" }}
              options={[
                {
                  label: Constant.A_Z,
                  value: "asc"
                },
                {
                  label: 'Price',
                  options: [
                    { label: Constant.PRICE_LOW_TO_HIGH, value: "price-asc" },
                    { label: Constant.PRICE_HIGH_TO_LOW, value: "price-desc" }
                  ]
                },
                {
                  label: 'THC',
                  options: [
                    { label: Constant.THC_LOW_TO_HIGH, value: "thc-low-high" },
                    { label: Constant.THC_HIGH_TO_LOW, value: "thc-high-low" }
                  ]
                },
                {
                  label: 'CBD',
                  options: [
                    { label: Constant.CBD_LOW_TO_HIGH, value: "cbd-low-high" },
                    { label: Constant.CBD_HIGH_TO_LOW, value: "cbd-high-low" }
                  ]
                },
                {
                  label: 'Popularity',
                  options: [
                    { label: Constant.MOST_POPULAR, value: "popularity-high-low" },
                    { label: Constant.LEAST_POPULAR, value: "popularity-low-high" }
                  ]
                },
              ]}
            />
          </Flex>
          <div className="mt-4">
            <div className="d-flex justify-content-between">

              <nav aria-label="breadcrumb">
                {
                  ready ?
                  <p className="deals">{title} ({products.length} products)</p>
                  :
                  <Skeleton.Input style={{ width: 200, marginBottom: 20 }} active={true} />
                }
              </nav>

            </div>
          </div>
          {filtersToShow.length > 0 && (

            <div className="mb-2">
              {filtersToShow.map((filter) => {
                return (
                  <Tag key={filter.displayName + filter.itemDisplayName}>
                    {filter.displayName}: {filter.itemDisplayName}
                    <i
                        className="fa fa-times"
                        onClick={() => removeFilter(filter.filterType, filter.id )}
                        style={{marginLeft: '4px'}}
                      />
                  </Tag>
                );
              })}
            </div>
        )}
        <div>

          <InfiniteScroll
            dataLength={cardLimit}
            next={() => setCardLimit(cardLimit + 20)}
            hasMore={cardLimit < sortedProducts?.length}
            loader={<LoadingCard />}
            endMessage={<Divider />}
          >
            {
              isMobile ?
              <>
              {
                sortedProducts?.slice(0, cardLimit).map((product, index) => (
                  <DynamicProductCard key={index} product={product} vertical={true} />
                ))
              }
              </>
              :
              <List
              loading={!ready}
              grid={{
                gutter: 2,
                xs: 1,
                sm: 2,
                md: 2,
                lg: 3,
                xl: 4,
                xxl: 4,
              }}
              dataSource={sortedProducts?.slice(0, cardLimit)}
              renderItem={(product) => (
                <List.Item>
                  <DynamicProductCard product={product} />
                </List.Item>
              )}
              />
            }
            </InfiniteScroll>
          </div>
        </div>
      </section>
    }
  </>
  );
}

export default ProductsScroll;
